









































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({})
export default class PpmIndicatorCard extends Vue {
  @Prop({ type: Number, required: true, default: -1 }) ppm!: number;

  VRMode = LocalStorage.getVRMode();

  get formattedPPM(): string {
    console.log("Get formattedPPM -> ", this.ppm);
    return this.ppm < 0 ? "Analysiere" : this.ppm.toFixed(1);
  }
  get valueClass() {
    if (this.ppm < 1) return "text-red";
    if (this.ppm < 2) return "text-blue";
    if (this.ppm <= 6) return "text-green";
    if (this.ppm <= 9) return "text-blue";
    return "text-red";
  }
  get statusClass() {
    if (this.ppm < 0) return "status-blue";
    if (this.ppm < 1) return "status-red";
    if (this.ppm < 2) return "status-blue";
    if (this.ppm <= 6) return "status-green";
    if (this.ppm <= 9) return "status-blue";
    return "status-red";
  }
  get statusMessage() {
    if (this.ppm < 0) return "Bitte warten";
    if (this.ppm < 1) return "Viel mehr";
    if (this.ppm < 2) return "Mehr Pausen";
    if (this.ppm <= 6) return "Perfekt";
    if (this.ppm <= 9) return "Weniger Pausen";
    return "Viel weniger";
  }

  mounted() {
    console.log("ppmIndicatorCard gemountet");
    console.log("ppm-Wert:", this.ppm);
  }

  @Watch("ppm", { immediate: true })
  ppmChanged() {
    console.log("Watcher ppm Changed! -> ", this.ppm);
  }
}
