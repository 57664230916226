
















































































































































































































































































































































































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

import LocalStorage from "@/core/utils/LocalStorage";
import ImageSlider from "@/components/common/ImageSlider.vue";
import {
  LookupWord,
  Pause,
  Presentation,
  Recording,
  RoleClaims,
  Session,
  SlideTimestamp,
  TranscriptionSegment,
  TranscriptionSlide,
  UserClaims,
  WordSegment,
  LookupWordType,
} from "@/core/models";
import {
  RecordList,
  PresentationSelect,
} from "@/views/liverecorder/components";
import VolumeTester from "@/views/recorder/components/volume-tester";
import LangSwitcher from "@/views/recorder/components/LangSwitcher.vue";
import Recorder from "@/core/utils/videoRecorder";
import { LiveTranscription } from "@/core/utils/transcription";
import { Analyzer } from "@/core/utils/analyzer";
import { convertDuration } from "@/views/recorder/components/utils";
import api from "@/core/utils/api";
import VolumeGauge from "@/views/recorder/components/volume-tester/Gauge.vue";
import DeviceSelect from "@/views/recorder/components/DeviceSelect.vue";
import WPMCard from "@/components/sessions/cards/stateless/WPMCard.vue";
import WpmIndicatorCard from "@/components/sessions/cards/stateless/WpmIndicatorCard.vue";
import PpmIndicatorCard from "@/components/sessions/cards/stateless/PpmIndicatorCard.vue";
import PausesCard from "@/components/sessions/cards/stateless/PausesCard.vue";
import VoicePitch from "@/components/sessions/cards/stateless/VoicePitch.vue";
import LookupWords from "@/components/sessions/cards/stateless/LookupWords.vue";
import { recordingSettings, transcriptionSettings } from "@/settings";
import { getDevices, selectDevice, Device } from "@/views/recorder/lib/devices";
import stopWordsDe from "stopwords-de";
import stopWordsEn from "stopwords-en";
import { UploadFileButton } from "@/components/common";
import { isDev, isLocal } from "@/settings";
import { createRecorderTour, Tour } from "@/core/utils/siteTour";

@Component({
  methods: { convertDuration },
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
    recordingSettings() {
      return recordingSettings;
    },
    isDev() {
      return isDev;
    },
    isLocal() {
      return isLocal;
    },
  },
  components: {
    WpmIndicatorCard,
    PpmIndicatorCard,
    UploadFileButton,
    LookupWords,
    VoicePitch,
    PausesCard,
    WPMCard,
    DeviceSelect,
    VolumeGauge,
    ImageSlider,
    LangSwitcher,
    RecordList,
    VolumeTester,
    PresentationSelect,
  },
})
export default class LiveRecorder extends Vue {
  //TODO convert to locals
  /* Video Recorder Props */
  @Prop({ default: () => true }) isTimed!: boolean;
  @Prop({ default: () => 12 * 60 }) expectedLen!: number;
  @Prop({ default: () => 14 * 60 }) warningLen!: number;
  @Prop({ default: () => recordingSettings.maxLen }) maxLen!: number;
  @Prop({ default: () => false }) loading!: boolean;
  /* -------------------- */

  @Getter("sessions/sessionsCount") sessionsCount!: number;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("profile/tutorialStep") tutorialStep!: number;
  @Getter("profile/permissions") permissions!: RoleClaims[];
  @Getter("profile/userClaims") userClaims!: UserClaims[];
  @PropSync("isRecordingProp") isRecording!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;
  @Action("presentations/getOne") getOne!: (
    id: number,
  ) => Promise<Presentation>;
  @Action("sessions/addSession") addSession!: (s: Session) => void;
  @Action("profile/fetchPermissions") fetchPermissions!: () => Promise<void>;

  @Getter("presentations/retrieved") retrieved!: boolean;
  @Action("presentations/getPresentations")
  getPresentations!: () => Promise<void>;

  /* Upload Slide Props */
  @Getter("presentations/loading") fileLoading!: boolean;
  @Getter("presentations/getUploadLoading") uploadLoading!: boolean;
  @Action("presentations/uploadPresentation") uploadPresentation: any;
  /* ------------------ */

  get analysisFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get analysisRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get liveTranscriptionFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "live_transcription:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get liveTranscriptionRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "live_transcription:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  showLiveStats = true;

  tour: Tour | null = null;

  // components
  recorder: Recorder = new Recorder();
  analyzer: Analyzer = new Analyzer();
  transcription = new LiveTranscription(() => {
    console.log("Transcription done!");
    // run custom action when recognize is done.
  });

  // objects
  pres: Presentation | null = null;
  recording: Recording | null = null;
  selectedPresentation: Presentation | null = null;
  presentation: Presentation | null = this.selectedPresentation;
  records: Recording[] = [];
  segments: TranscriptionSegment[] | null = null;
  slides: TranscriptionSlide[] | undefined = [];
  words: WordSegment[] = [];

  lang = LocalStorage.getLocale();

  // video recorder
  video: HTMLVideoElement | undefined;
  blob: Blob | undefined;
  blobs:
    | { videoBlob: Blob | undefined; audioBlob: Blob | undefined }
    | undefined;
  isTooBig = false;
  isPaused = false;
  isActive = false;
  flipped = false;

  // Calibration
  isCalibrating = false;
  calibrationProgress = 0;
  calibrationResults:
    | {
        volume: number;
        pitch: number;
        threshold: number;
        rmsLimit: number;
      }
    | undefined;

  // time controls
  elapsedTime = 0;
  interval: any = null;
  timeout: any = null;

  // Tester
  stopTester = false;

  // Analyzer
  analyzerReady = false;

  // pitch
  currentPitch = 0;
  currentNote = "";
  pitchAnalysis: { time: number; value: number }[] | any[] = [];
  avgPitch: number | undefined;

  // volume
  currentVolume = 0;
  volumeAnalysis: { time: number; value: number }[] | any[] = [];
  avgVolume: number | undefined;

  // pauses
  pauseAnalysis: Pause[] = [];
  avgPauseLength = 0;
  totalPauseTime = 0;
  pausesMade = 0;

  private pauses: Pause[] = [];

  // text transcription
  isTranscribing = false;
  completeText = "";
  previousText = "";
  newText = "";
  finalizedText = "";
  finalText = "";

  // filler & stopwords
  fillerWords =
    this.lang === "de"
      ? transcriptionSettings.fillerWordsDe
      : transcriptionSettings.fillerWordsEn;
  stopWords = this.lang === "de" ? stopWordsDe : stopWordsEn;

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // wpm
  displayLiveWpm = 0;
  wpmInterval: any = null;
  liveWpm = 0;
  finalWpm = 0;

  // Animate wpm change via watcher and computed prop
  get computedLiveWpm() {
    return this.liveWpm;
  }
  set computedLiveWpm(wpm: number) {
    this.liveWpm = wpm;
  }

  @Watch("liveWpm")
  liveWpmChanged() {
    clearInterval(this.wpmInterval);
    if (this.computedLiveWpm === this.displayLiveWpm) return;

    const transitionDuration = 1000;
    const transitionRefreshRate = 20;

    this.wpmInterval = setInterval(() => {
      if (!this.isPaused)
        if (
          Math.floor(this.displayLiveWpm) !== Math.floor(this.computedLiveWpm)
        ) {
          let change =
            (this.computedLiveWpm - this.displayLiveWpm) / transitionDuration;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayLiveWpm = this.displayLiveWpm + change;
        } else {
          this.displayLiveWpm = this.computedLiveWpm;
          clearInterval(this.wpmInterval);
        }
    }, transitionRefreshRate);
  }

  // misc
  //lang = "de";
  mode = "video";
  url: string | null = null;
  audioUrl: string | null = null;
  videoUrl: string | null = null;
  tipDialog = false;
  showVolume = false;

  // recorder locale
  recorderLocale = this.$i18n.locale as "en" | "de";

  devices: Device[] = [];
  selectedDevice: string | undefined;

  @Watch("$i18n.locale", { immediate: true })
  langChanged() {
    this.recorderLocale = this.$i18n.locale as "en" | "de";
  }
  @Watch("presentation")
  selectedChanged() {
    this.slideIndex = 0;
    this.addSlide();
  }
  @Watch("selectedPresentation")
  presentationChanged() {
    this.presentation = this.selectedPresentation;
  }
  @Watch("$route", { immediate: true })
  async routeChanged() {
    const { tour, presentation } = this.$route.query;

    // check slides retrieved
    if (!this.retrieved) await this.getPresentations();

    // check pres
    const id = Number(presentation);
    if (!isNaN(id)) this.selectedPresentation = await this.getOne(id);

    // check tour
    console.log("Live Recorder Tour");
    console.log("this.tutorialStep: ", this.tutorialStep);
    if (tour === "true" && this.showTutorial && this.tutorialStep === 0)
      this.startTour();
  }
  startTour() {
    this.tour = createRecorderTour();
    this.tour.start();
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (!this.isPaused) this.elapsedTime += 0.1;
    }, 100);
  }
  stopTimer() {
    clearInterval(this.interval);
  }
  startTimeout() {
    this.timeout = setTimeout(
      this.stop,
      (this.maxLen - this.elapsedTime) * 1000,
    );
  }
  stopTimeout() {
    clearTimeout(this.timeout);
  }

  async start() {
    if (this.devices.length === 0) {
      this.displaySnackbar(this.$t("recording.noCompatibleDevice").toString());
      return;
    }

    this.selectedDevice = this.selectedDevice || this.devices[0]?.id;

    if (!this.video || !this.recorder) {
      console.error("Video element or Videorecorder not available.");
      return;
    }

    this.reset();

    this.isRecording = true;

    this.slideTimestamps.push({
      index: this.tsIndex,
      offset: 0,
      duration: 0,
      slideIndex: this.slideIndex,
      slideURI: this.images[this.slideIndex],
    });

    this.tsIndex++;

    this.durationInterval = setInterval(() => {
      if (!this.isPaused) this.duration += 1;
    }, 1000);

    try {
      // Start recording => analyzer, and transcription parallel
      await Promise.all([
        this.startRecorder(true).then(async () => {
          await this.startAnalyzer().then(() => {
            this.analyzerReady = true;
          });
        }),
        this.startTranscription(this.selectedDevice as string),
      ]);
    } catch (error) {
      console.error("Error during startup:", error);
      return;
    }

    if (this.recorder.stream)
      this.video.srcObject = this.recorder.stream as MediaStream;
    else console.error("Recorder stream is undefined.");

    try {
      await this.video.play();
    } catch (error) {
      console.error("Error playing video:", error);
      return;
    }

    this.video.muted = true;
    this.video.controls = false;
    this.isActive = true;
    this.startTimer();

    if (this.isTimed) this.startTimeout();
  }

  async stop() {
    try {
      if (this.slideTimestamps.length > 0) {
        const lastTimestamp = this.slideTimestamps[
          this.slideTimestamps.length - 1
        ];
        lastTimestamp.duration = this.elapsedTime * 1000 - lastTimestamp.offset;
      }

      this.isTranscribing = false;

      const result = await this.recorder.stop();
      if (!result) return;

      const { videoBlob, audioBlob } = result;
      const blob = videoBlob;
      this.blobs = { videoBlob, audioBlob };

      if (videoBlob) this.videoUrl = URL.createObjectURL(videoBlob);

      if (audioBlob) this.audioUrl = URL.createObjectURL(audioBlob);

      this.isRecording = false;
      this.isPaused = false;
      if (this.isTimed) this.stopTimeout();
      this.stopTimer();
      this.elapsedTime = 0;
      this.shouldReset = true;

      this.showLiveStats = true;

      // clear duration
      clearInterval(this.durationInterval);

      // clear analyzer
      await this.analyzer.stop();

      const { segments, pauses } = await this.transcription.stop();

      //let totalWords = 0;
      let totalPausesTime = 0;
      let totalPausesPerMinute = 0;
      let totalPitch = 0;
      let totalVolume = 0;

      const lookupWords: { [key: string]: LookupWord } = {};

      this.segments = segments;
      this.pauseAnalysis = pauses;
      this.pauses = pauses;

      const allWords: WordSegment[] = [];

      //totalWords += segment.words ? segment.words.length : 0;

      for (const segment of this.segments)
        if (segment.words) {
          allWords.push(...segment.words);

          segment.words.forEach(word => {
            const existingWord = lookupWords[word.word];
            if (existingWord) existingWord.occurrences++;
            else
              lookupWords[word.word] = {
                text: word.word,
                occurrences: 1,
                type: this.fillerWords?.includes(word.word)
                  ? LookupWordType.filler
                  : this.stopWords?.includes(word.word)
                  ? LookupWordType.stop
                  : LookupWordType.default,
              };
          });
        }

      this.words = allWords;

      this.pauseAnalysis.forEach(pause => {
        totalPausesTime += pause.duration;
      });

      this.pitchAnalysis.forEach(pitch => {
        totalPitch += pitch.value;
      });

      this.volumeAnalysis.forEach(volume => {
        totalVolume += volume.value;
      });

      this.avgPitch = Math.round(totalPitch / this.pitchAnalysis.length);
      this.avgVolume = Math.round(totalVolume / this.volumeAnalysis.length);

      totalPausesTime =
        this.pauses.reduce((sum, pause) => sum + pause.duration, 0) / 1000;

      this.slides = this.createSlides();

      const durationInMinutes = this.duration / 60;

      this.finalWpm =
        Math.round((this.words.length / durationInMinutes) * 100) / 100;

      // Set final wpm result to wpm card
      this.computedLiveWpm = this.finalWpm;

      totalPausesPerMinute =
        Math.round((this.pauses.length / durationInMinutes) * 100) / 100 || 0;

      // create recording
      const now = new Date();
      this.recording = {
        id: 0,
        blob,
        audioBlob: audioBlob,
        videoBlob: videoBlob,
        type: this.mode as any,
        locale: this.lang as any,
        slideTimestamps: this.slideTimestamps,
        recordedAt: now.toISOString(),
        presentationId: this.presentation?.ID,
        title: `recording_${now.toLocaleDateString()}`,

        wpm: this.finalWpm,
        slides: this.slides,
        duration: this.duration,
        text: this.finalText,
      };

      const videoPlayback = document.querySelector(
        "#video-playback",
      ) as HTMLVideoElement;

      if (videoPlayback && this.videoUrl) {
        videoPlayback.src = this.videoUrl;
        videoPlayback.playsInline = true;
        videoPlayback.controls = true;
        videoPlayback.muted = false;
      }

      // Update quota delayed to be sure to catch the recent value with possible response lags
      setTimeout(async () => {
        await this.fetchPermissions();
      }, 500);

      // Reset recording if no text was recognized
      if (!this.finalText) {
        console.error("this.finalText was empty, resetting recording!");
        this.displaySnackbar(this.$t("upsessv.noText").toString());
        this.reset();
      }

      console.debug(`
LIVE-TRANSCRIPT-RESULTS
-----------------------

Voice pitch:
${this.currentPitch}Hz
Note:
${this.currentNote}
Volume:
${Math.round(this.currentVolume * 100)}%

Pitch over time:
${JSON.stringify(this.pitchAnalysis)}
Volume over time:
${JSON.stringify(this.volumeAnalysis)}
Pauses over time:
${JSON.stringify(this.pauseAnalysis)}
Average pitch:
${this.avgPitch}Hz
Average volume:
${this.avgVolume}%
Average pause length:
${this.avgPauseLength}s
Pauses made:
${this.pauseAnalysis.length}
Total pause time:
${totalPausesTime}s
Pauses per minute:
${totalPausesPerMinute}

Text:
${this.finalText}
Transcription Duration:
${this.durationFormatted}
Words Per Minute:
${this.finalWpm}
Words:
${JSON.stringify(this.totalWords)}
Lookup Words:
${JSON.stringify(this.totalLookupWords)}

Slide Timestamps:
${JSON.stringify(this.slideTimestamps)}

Slides:
${JSON.stringify(this.slides)}
      `);
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  }

  reset() {
    if (this.isPaused) this.togglePause();
    if (this.running) this.stop();
    this.recorder.reset();
    this.isRecording = false;
    this.isActive = false;
    this.duration = 0;
    this.slideIndex = 0;
    this.tsIndex = 0;
    this.slideTimestamps = [];
    this.shouldReset = true;
    this.url = null;
    this.videoUrl = null;
    this.audioUrl = null;
    this.slides = undefined;
    this.recording = null;
    this.pitchAnalysis = [];
    this.volumeAnalysis = [];
    this.pauseAnalysis = [];
    this.avgPitch = undefined;
    this.avgVolume = undefined;
    this.avgPauseLength = 0;
    this.totalPauseTime = 0;
    this.stopTimer();
    this.stopTimeout();
    this.elapsedTime = 0;
    this.words = [];
    this.isTranscribing = false;
    this.completeText = "";
    this.previousText = "";
    this.finalText = "";
    this.newText = "";
    this.finalizedText = "";
    this.displayLiveWpm = 0;
    this.computedLiveWpm = 0;

    if (!this.video) return;
    this.video.muted = true;
    this.video.currentTime = 0;
    this.video.removeAttribute("src");
    this.video.removeAttribute("srcObject");
    this.video.load();
  }
  // muting/gain
  muted = false;
  toggleMuted() {
    this.recorder?.toggleMuted();
    this.muted = !this.muted;
  }
  slideChanged(idx: number) {
    if (this.slideIndex !== idx) {
      this.slideIndex = idx;
      this.addSlide();
    }
  }

  // slide getters
  get totalWpm() {
    return Math.round((this.words.length / (this.duration / 60)) * 100) / 100;
  }

  get totalWords() {
    const words: WordSegment[] = [];
    this.slides?.forEach(slide =>
      words.push(...(slide.words as WordSegment[])),
    );
    return words;
  }

  get totalWordsCount() {
    return this.totalWords.length;
  }

  get totalLookupWords() {
    const lookupWords: LookupWord[] = [];
    this.slides?.forEach(slide => {
      slide.lookupWords.forEach(word => {
        const existing = lookupWords.find(lw => lw.text === word.text);
        if (existing) existing.occurrences += word.occurrences;
        else lookupWords.push(word);
      });
    });
    return lookupWords;
  }

  // duration stuff
  duration = 0;
  durationInterval: any;
  get durationFormatted() {
    if (this.running || this.recording)
      return convertDuration(Math.round(this.duration));
    else return convertDuration(0);
  }

  get pauseTimeTotal(): number {
    return Math.round(this.totalPauseTime / 1000);
  }

  get pausesPerMinute(): number {
    if (this.pauseAnalysis.length >= 1)
      return (
        (Math.round((this.pauseAnalysis.length / this.duration) * 60) / 10) * 10
      );

    return -1;
  }

  get calculatedWpm() {
    return this.finalWpm > 0 ? this.finalWpm : this.displayLiveWpm;
  }

  get running() {
    return this.recorder.isRecording && this.transcription.running;
  }

  // recording button style
  get buttonStyle() {
    const border = `border: 1px solid ${this.running ? "transparent" : "red"}`;
    const bgColor = `background-color: ${this.running ? "red" : "transparent"}`;
    return [border, bgColor].join(";");
  }

  uploading = false;
  askForFeedback = false;
  async upload() {
    if (!this.recording) return;

    this.uploading = true;

    try {
      const now = new Date();
      const name = `${this.$t("rec.prefix")} ${now.toLocaleString()}`;

      // create data object
      const data = new FormData();
      data.append("type", this.recording.type);
      data.append("audioFile", this.recording.audioBlob || null);
      data.append("videoFile", this.recording.videoBlob || null);
      data.append("title", name);
      data.append("locale", this.recording.locale);
      data.append("wpm", this.recording.wpm!.toString());
      data.append("text", JSON.stringify(this.recording.text));
      data.append("words", JSON.stringify(this.totalWords));
      data.append("lookupWords", JSON.stringify(this.totalLookupWords));
      data.append("slides", JSON.stringify(this.recording.slides));
      data.append(
        "duration",
        JSON.stringify(Number(this.recording.duration!.toFixed(2))),
      );
      data.append(
        "slideTimestamps",
        JSON.stringify(this.recording.slideTimestamps),
      );
      data.append("recordedAt", this.recording.recordedAt);
      data.append("pitchAnalysis", JSON.stringify(this.pitchAnalysis));
      data.append("avgPitch", (this.avgPitch || 0).toString());
      data.append("volumeAnalysis", JSON.stringify(this.volumeAnalysis));
      data.append("avgVolume", (this.avgVolume || 0).toString());
      data.append("pauseAnalysis", JSON.stringify(this.pauseAnalysis));
      data.append("avgPauseLength", this.avgPauseLength.toString());
      data.append("totalPauseTime", (this.pauseTimeTotal || 0).toString());
      data.append("pausesPerMinute", (this.pausesPerMinute || 0).toString());
      data.append("transcribedSegments", JSON.stringify(this.segments));
      if (this.presentation)
        data.append(
          "presentationId",
          this.recording.presentationId?.toString() || "0",
        );

      // send to api
      const session = (await api.post("/api/LiveTranscription/New", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as Session;
      this.addSession(session);
    } catch (error) {
      console.error(error);
    }
    this.uploading = false;
    this.reset();

    await this.$router.push("/sessions/list");
  }

  togglePause() {
    if (this.isPaused) {
      this.isPaused = false;
      this.recorder.resume();
      this.transcription.resume();
      this.startTimer();
      if (this.isTimed) this.startTimeout();
    } else {
      this.isPaused = true;
      this.recorder.pause();
      this.transcription.pause();
      this.stopTimer();
      if (this.isTimed) this.stopTimeout();
      clearInterval(this.wpmInterval);
    }
  }

  async refreshDevices() {
    this.devices = await getDevices("audio");
    this.selectedDevice = LocalStorage.getInputDevice();
  }

  async selectInputDevice(deviceId: string) {
    selectDevice(deviceId, "audio");
    await this.refreshDevices();
  }

  addSlide() {
    if (
      this.slideTimestamps.length > 0 &&
      this.slideTimestamps[this.slideTimestamps.length - 1].slideIndex ===
        this.slideIndex
    )
      return;

    if (this.slideTimestamps.length > 0) {
      const lastTimestamp = this.slideTimestamps[
        this.slideTimestamps.length - 1
      ];
      lastTimestamp.duration = Math.max(
        this.elapsedTime * 1000 - lastTimestamp.offset,
        0,
      );
    }

    const ts: SlideTimestamp = {
      index: this.tsIndex,
      offset: this.elapsedTime * 1000,
      duration: 0,
      slideIndex: this.slideIndex,
      slideURI: this.images[this.slideIndex],
    };

    this.slideTimestamps.push(ts);

    this.tsIndex++;
  }

  async handleButtonClick() {
    if (this.isPaused) this.togglePause();
    else if (LocalStorage.getRecordLimitDialog())
      this.toggleRecordLimitWarning(!this.isRecordLimitWarning, async () => {
        //await this.startCalibrationWithProgress();
        //this.applyCalibration();
        this.start();
      });
    else
      try {
        this.isCalibrating = true;
        this.calibrationProgress = 0;

        //await this.startCalibrationWithProgress();
        //this.applyCalibration();

        this.start();
      } catch (error) {
        console.error("Could not start calibration and recording:", error);
      } finally {
        this.isCalibrating = false;
      }
  }

  startCalibrationWithProgress() {
    return new Promise<void>((resolve, reject) => {
      try {
        this.calibrationProgress = 0;
        this.isCalibrating = true;

        const updateProgress = (progress: number) => {
          this.calibrationProgress = progress;
        };

        this.analyzer.onCalibrationProgress = updateProgress;

        const calibrationPromise = this.analyzer.startCalibration(
          updateProgress,
        );

        calibrationPromise
          .then(results => {
            this.calibrationResults = results;
            this.displaySnackbar(this.$t("calibration.completed").toString());
            resolve();
          })
          .catch(error => {
            console.error("Calibration failed:", error);
            this.displaySnackbar(this.$t("calibration.failed").toString());
            reject(error);
          })
          .finally(() => {
            this.isCalibrating = false;
            this.calibrationProgress = 100;
          });
      } catch (error) {
        console.error("Calibration setup failed:", error);
        this.displaySnackbar(this.$t("calibration.failed").toString());
        reject(error);
      }
    });
  }

  applyCalibration() {
    if (this.calibrationResults) {
      this.analyzer.applyCalibration(this.calibrationResults);
      this.displaySnackbar(this.$t("calibration.applied").toString());
    }
  }

  async startRecorder(includeVideo: boolean) {
    try {
      await this.recorder.start(
        {
          onError: (errorMessage: string) => this.displaySnackbar(errorMessage),
        },
        includeVideo,
      );
    } catch (error) {
      console.error("Error starting recorder:", error);
      throw error;
    }

    if (!this.recorder.stream) {
      console.error("Failed to start recording stream.");
      throw new Error("Recording stream not started.");
    }
  }

  async startAnalyzer() {
    try {
      if (this.recorder.stream)
        await this.analyzer.start(this.recorder.stream, data => {
          if (!data) throw "Analyzer not ready";

          if (data.volume > 0) {
            this.currentVolume = data.volume;
            this.volumeAnalysis.push({ time: data.time, value: data.volume });
          }
          if (data.pitch >= 50 && data.pitch <= 600) {
            this.currentPitch = Math.round(data.pitch);
            this.currentNote = data.note;
            this.pitchAnalysis.push({ time: data.time, value: data.pitch });
          }
        });
      else console.error("Recorder stream is undefined.");
    } catch (error) {
      console.error("Error starting analyzer:", error);
      throw error;
    }
  }

  async startTranscription(selectedDeviceId: string) {
    try {
      await this.transcription.start(
        this.recorder.stream,
        this.lang === "en" ? "en-US" : "de-DE",
        selectedDeviceId,
        (text, isTranscribing, segment, pauses) => {
          this.isTranscribing = isTranscribing;

          // Handle initial recognition, before first segment or slide is completed
          if (!segment || this.slideTimestamps.length === 0) {
            this.previousText = this.completeText;
            this.completeText = text;
            this.finalText = text.replace(/\r?\n/g, " ").trim();
            return;
          }

          // Pauses calculations
          if (pauses && pauses.length > 0) this.pauseAnalysis = [...pauses];

          this.totalPauseTime = this.pauseAnalysis
            .map(x => x.duration)
            .reduce((cum, cur) => cum + cur, 0);
          const totalPauses = this.pauseAnalysis.length;
          this.avgPauseLength = Math.round(
            this.totalPauseTime / totalPauses / 1000,
          );
          if (isNaN(this.avgPauseLength)) this.avgPauseLength = 0;

          //const currentText = text.replace(/\r?\n/g, " ").trim();

          // Get all values for the followup recognition callbacks upon first slide create
          this.previousText = this.completeText;
          this.completeText = text;
          this.finalizedText = text.replace(/\r?\n?[^\r\n]*$/, "");
          this.newText = text.split(/\r?\n/).slice(-1)[0];

          this.finalText = text.replace(/\r?\n/g, " ").trim();

          const wordsArray = this.finalText.split(/\s+/);
          const totalWords = wordsArray.length;
          const totalDurationMinutes = this.duration / 60;
          const currentWpm =
            totalDurationMinutes > 0
              ? Math.round(totalWords / totalDurationMinutes)
              : 0;

          this.computedLiveWpm = currentWpm;

          console.log("isTranscribing: ", isTranscribing);
          if (!isTranscribing) this.words.push(...segment.words);

          // Calculate gaps between segments for 1. detailed analysis 2. improvment of wpm by adding large gaps to calc 3. to help debug wrong segment trennungen
          const lastWord = this.words[this.words.length - 1];
          if (lastWord) {
            const gap = segment.offset - (lastWord.offset + lastWord.duration);
            if (gap > 0)
              console.debug(`Detected gap of ${gap}ms between segments.`);
          }

          // Update the WPM value (this.displayLiveWpm should be updated here)
          //this.displayLiveWpm = totalWpm;

          // Re-trigger css blink animation
          const liveSentence = document.getElementById("liveSentence");
          liveSentence?.classList.remove("text-blink");
          liveSentence?.classList.add("font-weight-black");
          void liveSentence?.offsetWidth; // Trigger reflow
          liveSentence?.classList.add("text-blink");
          setTimeout(() => {
            liveSentence?.classList.remove("font-weight-black");
          }, 850);
        },
      );
    } catch (error) {
      console.error("Error starting transcription:", error);
      throw error;
    }
  }

  closeTip() {
    //if (this.dontShowTip) LocalStorage.setShouldShowRecordingTip(false);
    //this.dontShowTip = true;
    this.tipDialog = false;
  }

  // Slides
  tsIndex = 0;
  slideIndex = 0;
  shouldReset = false;
  slideTimestamps: SlideTimestamp[] = [];

  get images() {
    if (!this.presentation) return [];
    return this.presentation.Slides.map(x => x.Uri);
  }
  get maxWidth() {
    return undefined;
  }
  get maxHeight() {
    return undefined;
  }
  created() {
    window.onbeforeunload = () => {
      if (this.running)
        return "The recording is still running... Are you sure you wanna exit?";

      if (this.recording)
        return "You have an unsaved recording. Are you sure you want to leave?";
    };
  }
  mounted() {
    this.video = document.querySelector("#video") as HTMLVideoElement;
    this.video.controls = false;

    this.showVolume = true;
    const show = LocalStorage.getShouldShowRecordingTip();
    if (show && this.$route.query.tour !== "true")
      this.$nextTick(() => (this.tipDialog = true));
    document.documentElement.classList.add("no-scroll");

    this.refreshDevices();

    void this.fetchPermissions();
  }

  beforeDestroy() {
    //this.stop();
    window.onbeforeunload = null;
  }

  handleUpload() {
    if ((this.recording?.duration ?? 0) < recordingSettings.durationThreshold)
      this.toggleShortRecordWarning(true);
    else this.upload();
  }

  confirmUpload() {
    this.upload();
    this.toggleShortRecordWarning(false);
  }

  // dialogs: recording duration
  isShortRecordWarning = false;

  toggleShortRecordWarning(visible: boolean) {
    this.isShortRecordWarning = visible;
  }

  // dialogs: record limit
  isRecordLimitWarning = false;
  recordLimitCallback: () => void = () => null;
  recordLimitWarningChecked = !LocalStorage.getRecordLimitDialog();

  setRecordLimitWarningChecked(checked: boolean) {
    LocalStorage.setRecordLimitDialog(!checked);
    this.recordLimitWarningChecked = checked;
  }

  toggleRecordLimitWarning(visible: boolean, confirmationCallback: () => void) {
    this.recordLimitWarningChecked = !LocalStorage.getRecordLimitDialog();
    this.isRecordLimitWarning = visible;
    this.recordLimitCallback = confirmationCallback;
  }

  private createSlides(): TranscriptionSlide[] {
    if (!this.segments || this.segments.length === 0) return [];

    const slides: TranscriptionSlide[] = [];

    for (let i = 0; i < this.slideTimestamps.length; i++) {
      const timestamp = this.slideTimestamps[i];
      const nextTimestamp = this.slideTimestamps[i + 1];

      const duration = nextTimestamp
        ? nextTimestamp.offset - timestamp.offset
        : this.duration * 1000 - timestamp.offset;

      const words: WordSegment[] = this.segments
        .flatMap(segment => segment.words || [])
        .filter(
          word =>
            word.offset >= timestamp.offset &&
            word.offset < (nextTimestamp?.offset || Infinity),
        );

      const text = words.map(word => word.displayWord || word.word).join(" ");

      const lookupWords: LookupWord[] = [];
      words.forEach(word => {
        const existingWord = lookupWords.find(lw => lw.text === word.word);
        if (existingWord) existingWord.occurrences++;
        else
          lookupWords.push({
            text: word.word,
            occurrences: 1,
            type: this.fillerWords?.includes(word.word)
              ? LookupWordType.filler
              : this.stopWords?.includes(word.word)
              ? LookupWordType.stop
              : LookupWordType.default,
          });
      });

      const pausesMade = this.pauses.filter(
        x => x.offset >= timestamp.offset && x.offset < nextTimestamp?.offset,
      ).length;
      const totalPauseTime = this.pauses
        .filter(
          x => x.offset >= timestamp.offset && x.offset < nextTimestamp?.offset,
        )
        .reduce((sum, pause) => sum + pause.duration, 0);

      const avgPauseLength = pausesMade
        ? Math.round((totalPauseTime / pausesMade) * 10) / 10
        : 0;

      const wordsPerMinute =
        Math.round((words.length / (duration / 1000 / 60)) * 100) / 100;

      slides.push({
        text,
        slide: i,
        offset: timestamp.offset,
        duration,
        words,
        lookupWords,
        wpm: wordsPerMinute,
        pausesMade,
        totalPauseTime,
        avgPauseLength,
        ppm: Math.round((pausesMade / (duration / 1000 / 60)) * 100) / 100,
        pausesPerMinute: pausesMade / (duration / 1000 / 60),
        image: this.images[timestamp.slideIndex] || "",
      });
    }

    return slides;
  }

  uploadFile(file: any) {
    if (this.uploadLoading) return;
    this.uploadPresentation(file);
  }
}
