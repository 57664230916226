











































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({})
export default class WpmIndicatorCard extends Vue {
  @Prop({ type: Number, required: true, default: 0 }) wpm!: number;

  VRMode = LocalStorage.getVRMode();

  get valueClass() {
    if (this.wpm < 80) return "text-red";
    if (this.wpm < 95) return "text-blue";
    if (this.wpm <= 145) return "text-green";
    if (this.wpm <= 160) return "text-blue";
    return "text-red";
  }
  get statusClass() {
    if (this.wpm < 80) return "status-red";
    if (this.wpm < 95) return "status-blue";
    if (this.wpm <= 145) return "status-green";
    if (this.wpm <= 160) return "status-blue";
    return "status-red";
  }
  get statusMessage() {
    if (this.wpm < 80) return "Schneller sprechen";
    if (this.wpm < 95) return "Etwas schneller";
    if (this.wpm <= 145) return "Perfekt";
    if (this.wpm <= 160) return "Etwas langsamer";
    return "Langsamer sprechen";
  }

  mounted() {
    //console.log("WpmIndicatorCard gemountet");
    //console.log("wpm-Wert:", this.wpm);
  }

  @Watch("wpm", { immediate: true })
  wpmChanged() {
    //console.log("Watcher WpM CHanged! -> ", this.wpm);
  }
}
